@mixin desktop() {
    @media (max-width:1000px) {
        @content
    }
}

@mixin tablet() {
    @media (max-width:880px) {
        @content
    }
}

@mixin phone() {
    @media (max-width:400px) {
        @content
    }
}
