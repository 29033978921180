@font-face {
    font-family: 'IBM Plex Sans';
    src: url('/fonts/IBMPlexSans-Medium.eot');
    src: url('/fonts/IBMPlexSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/IBMPlexSans-Medium.woff2') format('woff2'),
    url('/fonts/IBMPlexSans-Medium.woff') format('woff'),
    url('/fonts/IBMPlexSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('/fonts/IBMPlexSans.eot');
    src: url('/fonts/IBMPlexSans.eot?#iefix') format('embedded-opentype'),
    url('/fonts/IBMPlexSans.woff2') format('woff2'),
    url('/fonts/IBMPlexSans.woff') format('woff'),
    url('/fonts/IBMPlexSans.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    #root {
        height: 100%;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-height: 550px;
    min-width: 290px;
    position: relative;

    @include tablet() {
        display: table;
    }
}

.page-background {
    background-color: $color-1;
}

.container {
    width: 990px;
    margin: auto;
    padding: 0 15px;
    box-sizing: border-box;

    @include desktop() {
        width: 100%;
    }
}
.container-style {
    display: block;
    box-sizing: border-box;
    padding: 32px;
    margin-bottom: 15px;
    border-radius: 2px;
    background-color: $color-6;
    border: 1px solid $color-5;

    @include tablet() {
        padding: 14px;
    }
}
