@import '../mixins';
@import '../variables';
.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-6;
    z-index: 1000;
}
.preloader__icon {
    width: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.preloader__circle {
    width: 35px;
    height: 35px;
    border: 2px solid $color-4;
    border-radius: 50%;
    transition: 1s;
    animation: bounce 1s ease-in-out  infinite both;
}

@keyframes bounce {
    0% {
        transform: scale(0);
    }
    90% {
      transform: scale(1);
    }
}
