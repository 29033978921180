.img-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    margin: 32px -5px -25px 0;
    list-style: none;

    @include tablet() {
        margin: 14px -5px -14px 0;
    }
}
.img-card {
    width: 31.545%;
    max-width: 282px;
    height: 282px;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-bottom: 25px;
    margin-right: 5px;

    @include tablet() {
        width: 120px;
        height: 120px;
        margin-bottom: 14px;
    }

    @include phone() {
        width: 110px;
        height: 110px;
    }

    img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    div {
        display: none;
    }

    .img-card__small {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;

        &:after {
            content: '';
            display: block;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            border-radius: 4px;
            transition: background-color 0.2s;
        }

        &:hover {
            &:after {
                background: url("/icons/look.svg") center center no-repeat, rgba(51, 51, 51, 0.75);
            }
        }

        @include tablet() {
            &:after {
                background-size: 20%;
            }
        }
    }
    .img-card__large {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $color-10;
        z-index: 1;

        img {
            width: auto;
            height: auto;
            max-width: 95vw;
            max-height: 95vh;
        }
    }
    .img-card__preloader {
        display: flex;
        width: 100%;
        height: 100%;
        position: absolute;
        justify-content: center;
        align-items: center;
        z-index: 1;
        color: #fff;
    }
    .img-hidden {
        display: none;
    }


    .svg {
        width: 50px;
        height: 50px;
        transform: rotate(0);
        animation: rotate 2s cubic-bezier(.51, .92, .24, 1) forwards infinite;
    }
    .path {
        stroke-dasharray: 590;
        stroke-dashoffset: 0;
        stroke: $color-4;
        fill: none;
        animation: dash 2s cubic-bezier(.51, .92, .24, 1) forwards infinite;
    }

    @keyframes dash {
        from {
            stroke-dashoffset: 1200;
        }
        to {
            stroke-dashoffset: 0;
        }
    }

    @keyframes rotate {
        from {
            transform: rotate(-360deg);
        }
        to {
            transform: rotate(0);
        }
    }
}
