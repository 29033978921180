.catalog-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-radius: 0;
  border-top: none;
  border-bottom: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
}
@media (max-width: 880px) {
  .catalog-list {
    height: auto;
    background: transparent;
    border: none;
  }
}

.catalog-list__mobile {
  display: none;
}
@media (max-width: 880px) {
  .catalog-list__mobile {
    display: inline-block;
  }
}

.catalog-list__header {
  display: flex;
  align-items: center;
  padding: 14px;
  border-bottom: 1px solid #E0E0E0;
}
@media (max-width: 880px) {
  .catalog-list__header {
    display: none;
  }
}

.catalog-list__footer {
  display: flex;
  flex: 0 0 48px;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  box-sizing: border-box;
  border-top: 1px solid #E0E0E0;
}
@media (max-width: 880px) {
  .catalog-list__footer {
    padding: 14px;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    background-color: #FFFFFF;
    margin-bottom: 8px;
  }
}

.catalog-list__main {
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.catalog-list__main::-webkit-scrollbar {
  width: 0px;
}

.catalog-list__products {
  list-style: none;
  padding: 0 14px;
  position: relative;
}
@media (max-width: 880px) {
  .catalog-list__products {
    padding: 0;
  }
}

.catalog-list__product {
  display: flex;
  padding: 18px 0;
  border-bottom: 1px solid #E0E0E0;
}
.catalog-list__product:last-of-type {
  border-bottom: 0;
}
@media (max-width: 880px) {
  .catalog-list__product {
    flex-wrap: wrap;
    padding: 14px;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    background-color: #FFFFFF;
    margin-bottom: 8px;
  }
  .catalog-list__product:last-of-type {
    border: 1px solid #E0E0E0;
  }
}

.catalog-list__price {
  display: block;
  width: 100%;
  padding-left: 26px;
  position: relative;
  box-sizing: border-box;
}
.catalog-list__price:before {
  content: "";
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  top: 0;
  background: url("/icons/card.svg");
}
@media (max-width: 880px) {
  .catalog-list__price {
    display: inline;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

@media (max-width: 880px) {
  .catalog-list__price_mobile {
    margin-right: 15px;
  }
}

.catalog-list__promoutions {
  padding-right: 32px;
  position: relative;
}
.catalog-list__promoutions:after {
  content: "";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  right: 0;
  top: -6px;
  background: url("/icons/percent.svg");
}
@media (max-width: 880px) {
  .catalog-list__promoutions:after {
    right: -10px;
    top: auto;
    bottom: -7px;
  }
}
@media (max-width: 880px) {
  .catalog-list__promoutions {
    padding-right: 22px;
  }
}

.catalog-list__col1,
.catalog-list__col2,
.catalog-list__col3 {
  padding-right: 15px;
}
@media (max-width: 880px) {
  .catalog-list__col1,
  .catalog-list__col2,
  .catalog-list__col3 {
    padding-right: 0;
  }
}

.catalog-list__col1,
.catalog-list__col2,
.catalog-list__col3,
.catalog-list__col4 {
  display: block;
  box-sizing: border-box;
}

.catalog-list__col1 {
  width: 51.5%;
}
@media (max-width: 880px) {
  .catalog-list__col1 {
    width: 100%;
    order: 1;
    margin-bottom: 12px;
  }
}

.catalog-list__col2 {
  width: 17.5%;
}
@media (max-width: 880px) {
  .catalog-list__col2 {
    width: auto;
    max-width: 75%;
    order: 3;
    margin-bottom: 12px;
  }
}

.catalog-list__col3 {
  width: 9.6%;
}
@media (max-width: 880px) {
  .catalog-list__col3 {
    width: auto;
    max-width: 25%;
    order: 2;
    padding-right: 14px;
  }
}

.catalog-list__col4 {
  width: 21.4%;
}
@media (max-width: 880px) {
  .catalog-list__col4 {
    width: 100%;
    order: 4;
  }
}

.pagination {
  display: flex;
}

.pagination__button {
  min-width: 32px;
  max-width: 70px;
  height: 32px;
  vertical-align: middle;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-right: none;
  padding: 0 3px;
  margin: 0;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;
  overflow: hidden;
  color: #333333;
  font-size: 0.8125rem;
}
.pagination__button:hover {
  background-color: #FAFEF6;
  color: #7CB342;
}
.pagination__button:first-of-type {
  border-radius: 4px 0 0 4px;
}
.pagination__button:last-of-type {
  border-radius: 0 4px 4px 0;
  border-right: 1px solid #BDBDBD;
}

.pagination__button_left {
  background: url("/icons/arrow-left.svg") 12px center no-repeat;
}

.pagination__button_right {
  background: url("/icons/arrow-right.svg") 8px center no-repeat;
}

.pagination__button_active {
  background-color: #FAFEF6;
  color: #7CB342;
  font-weight: 500;
}

.promotions {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.promotions .text-title-mobile {
  color: #D67196;
}

.promotions__container {
  height: 100%;
}