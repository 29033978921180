@import '../mixins';
@import '../variables';

.navigation-container {
    position: relative;
}
.navigation {
    display: flex;
    list-style: none;
    padding: 0;
    text-transform: uppercase;
    flex-wrap: wrap;

    @include tablet() {
        visibility: hidden;
        margin-left: -9999px;
        opacity: 0;
        width: 157px;
        max-height: 80vh;
        position: absolute;
        z-index: 2;
        right: 0;
        top: 0;
        background-color: $color-6;
        border-radius: 2px;
        border: 1px solid $color-5;
        margin: 0;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        text-transform: none;
        font-weight: $regular;
        font-size: 0.9375rem;
        overflow-y: auto;
        transition-property: transform;
        transition-duration: 0.3s;
        transition-delay: 0s, 0s;
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        transform: scale(0);
        transform-origin: 90% 0;
        opacity: 0;
    }
}
.active-dropdown {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    & + .navigation {
        @include tablet() {
            visibility: visible;
            margin-left: 0;
            opacity: 1;
            transform: scale(1);
        }
    }
}
.navigation__item {
    margin-right: 36px;

    &:last-of-type {
        margin-right: 0;
    }

    @include tablet() {
        margin: 0;
        width: 100%;
        border-bottom: 1px solid $color-5;
        box-sizing: border-box;
        padding: 18px 16px;

        &:last-of-type {
            border-bottom: none;
        }
    }
}
.navigation__link {
    color: inherit;
    text-decoration: none;
    transition: opacity 0.2s;

    &:hover {
        opacity: 0.8;
    }
}
.navigation__link.active {
    color: $color-4;

    @include tablet() {
        color: $color-7;
    }
}
.navigation__link.navigation__link_icon {
    padding-left: 40px;
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 32px;
        height: 32px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url("/icons/percent.svg");
    }

    @include tablet() {
        display: block;
        position: relative;
        padding-left: 0;

        &:before {
            right: 0;
            left: inherit;
        }
    }
}
.navigation-button {
    display: none;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    border: 2px solid $color-4;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    transition: 0.2s background-color;
    background-color: transparent;

    &:hover {
        background-color: $color-4;

        .navigation-button__element {
            background-color: $color-6;
        }
    }

    .navigation-button__element {
        width: 16px;
        height: 2px;
        border-radius: 20px;
        background-color: $color-4;
        box-sizing: border-box;
        margin-bottom: 4px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    @include tablet() {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
