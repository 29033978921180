.catalog-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border-radius: 0;
    border-top: none;
    border-bottom: none;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    list-style: none;
    overflow-x: hidden;

    @include tablet() {
        height: auto;
        background: transparent;
        border: none;
    }
}
.catalog-list__mobile {
    display: none;
    @include tablet() {
        display: inline-block;
    }
}
.catalog-list__header {
    display: flex;
    align-items: center;
    padding: 14px;
    border-bottom: 1px solid $color-5;

    @include tablet() {
        display: none;
    }
}
.catalog-list__footer {
    display: flex;
    flex: 0 0 48px;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    box-sizing: border-box;
    border-top: 1px solid $color-5;

    @include tablet() {
        padding: 14px;
        border: 1px solid $color-5;
        border-radius: 2px;
        background-color: $color-6;
        margin-bottom: 8px;
    }
}

.catalog-list__main {
    height: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        width: 0px;
    }
}
.catalog-list__products {
    list-style: none;
    padding: 0 14px;
    position: relative;

    @include tablet() {
        padding: 0;
    }
}
.catalog-list__product {
    display: flex;
    padding: 18px 0;
    border-bottom: 1px solid $color-5;

    &:last-of-type {
        border-bottom: 0;
    }

    @include tablet() {
        flex-wrap: wrap;
        padding: 14px;
        border: 1px solid $color-5;
        border-radius: 2px;
        background-color: $color-6;
        margin-bottom: 8px;

        &:last-of-type {
            border: 1px solid $color-5;
        }
    }
}
.catalog-list__price {
    display: block;
    width: 100%;
    padding-left: 26px;
    position: relative;
    box-sizing: border-box;

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 18px;
        height: 18px;
        left: 0;
        top: 0;
        background: url("/icons/card.svg");
    }

    @include tablet() {
        display: inline;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
.catalog-list__price_mobile {
    @include tablet() {
        margin-right: 15px;
    }
}
.catalog-list__promoutions {
    padding-right: 32px;
    position: relative;

    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 32px;
        height: 32px;
        right: 0;
        top: -6px;
        background: url("/icons/percent.svg");

        @include tablet() {
            right: -10px;
            top: auto;
            bottom: -7px;
        }
    }

    @include tablet() {
        padding-right: 22px;
    }
}
.catalog-list__col1,
.catalog-list__col2,
.catalog-list__col3 {
    padding-right: 15px;

    @include tablet() {
        padding-right: 0;
    }
}
.catalog-list__col1,
.catalog-list__col2,
.catalog-list__col3,
.catalog-list__col4 {
    display: block;
    box-sizing: border-box;
}
.catalog-list__col1 {
    width: 51.5%;

    @include tablet() {
        width: 100%;
        order: 1;
        margin-bottom: 12px;
    }
}
.catalog-list__col2 {
    width: 17.5%;

    @include tablet() {
        width: auto;
        max-width: 75%;
        order: 3;
        margin-bottom: 12px;
    }
}
.catalog-list__col3 {
    width: 9.6%;

    @include tablet() {
        width: auto;
        max-width: 25%;
        order: 2;
        padding-right: 14px;
    }
}
.catalog-list__col4 {
    width: 21.4%;

    @include tablet() {
        width: 100%;
        order: 4;
    }
}
