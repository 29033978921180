.img-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin: 32px -5px -25px 0;
  list-style: none;
}
@media (max-width: 880px) {
  .img-items {
    margin: 14px -5px -14px 0;
  }
}

.img-card {
  width: 31.545%;
  max-width: 282px;
  height: 282px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-bottom: 25px;
  margin-right: 5px;
}
@media (max-width: 880px) {
  .img-card {
    width: 120px;
    height: 120px;
    margin-bottom: 14px;
  }
}
@media (max-width: 400px) {
  .img-card {
    width: 110px;
    height: 110px;
  }
}
.img-card img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-card div {
  display: none;
}
.img-card .img-card__small {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.img-card .img-card__small:after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 4px;
  transition: background-color 0.2s;
}
.img-card .img-card__small:hover:after {
  background: url("/icons/look.svg") center center no-repeat, rgba(51, 51, 51, 0.75);
}
@media (max-width: 880px) {
  .img-card .img-card__small:after {
    background-size: 20%;
  }
}
.img-card .img-card__large {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
.img-card .img-card__large img {
  width: auto;
  height: auto;
  max-width: 95vw;
  max-height: 95vh;
}
.img-card .img-card__preloader {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #fff;
}
.img-card .img-hidden {
  display: none;
}
.img-card .svg {
  width: 50px;
  height: 50px;
  transform: rotate(0);
  animation: rotate 2s cubic-bezier(0.51, 0.92, 0.24, 1) forwards infinite;
}
.img-card .path {
  stroke-dasharray: 590;
  stroke-dashoffset: 0;
  stroke: #7CB342;
  fill: none;
  animation: dash 2s cubic-bezier(0.51, 0.92, 0.24, 1) forwards infinite;
}
@keyframes dash {
  from {
    stroke-dashoffset: 1200;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(0);
  }
}

.supply .container-style {
  margin: 24px 0;
}