.background-block {
  background: url("/background.jpg") center left no-repeat;
  background-size: 100%;
  height: 276px;
  position: relative;
}
.background-block + .container {
  position: relative;
  margin-top: -130px;
}
@media (max-width: 880px) {
  .background-block + .container {
    margin-top: 0;
  }
}
.background-block .title-container {
  position: relative;
  padding: 50px 0;
}
.background-block .title-container * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.background-block:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.75);
  top: 0;
  left: 0;
}
@media (max-width: 880px) {
  .background-block {
    display: none;
  }
}

.contacts__cols {
  display: flex;
}

.contacts__list {
  width: 50%;
  list-style: none;
  padding: 0 5% 0 0;
  margin: 0;
  box-sizing: border-box;
}
.contacts__list .contacts__item {
  margin-bottom: 13px;
}
.contacts__list .contacts__item:last-of-type {
  margin-bottom: 0;
}

.contacts__item_phone .contacts__link,
.contacts__item_location .contacts__link,
.contacts__item_mail .contacts__link {
  padding-left: 29px;
  position: relative;
}
.contacts__item_phone .contacts__link:before,
.contacts__item_location .contacts__link:before,
.contacts__item_mail .contacts__link:before {
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 18px;
  left: 5px;
  top: 1px;
}

.contacts__item_phone .contacts__link {
  word-break: break-word;
}
.contacts__item_phone .contacts__link:before {
  background: url("/icons/phone.svg") center left no-repeat;
}

.contacts__item_location .contacts__link:before {
  background: url("/icons/location.svg") center center no-repeat;
}

.contacts__item_mail .contacts__link {
  word-break: break-word;
}
.contacts__item_mail .contacts__link:before {
  top: 2px;
  background: url("/icons/mail.svg") center center no-repeat;
}

.contacts__item_marker .contacts__title {
  color: #D67196;
}
.contacts__item_marker .contacts__link:before {
  top: 2px;
  background: url("/icons/phone-red.svg") center center no-repeat;
}

.contacts__title {
  display: block;
  margin-bottom: 4px;
}

.contacts__link {
  display: block;
  color: inherit;
  text-decoration: none;
}
.contacts__link span {
  margin-left: 10px;
}

@media (max-width: 880px) {
  .contacts-page .contacts__cols {
    flex-direction: column;
    background: transparent;
    border: none;
    padding: 0;
  }
}
@media (max-width: 880px) {
  .contacts-page .contacts__list {
    width: 100%;
    padding: 14px;
    background-color: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #E0E0E0;
    margin-bottom: 8px;
  }
  .contacts-page .contacts__list:last-of-type {
    margin-bottom: 0;
  }
}