.list {
    margin: 0;
    padding: 0;
}
.list-with-img {
    .list__item {
        display: flex;
        min-height: 150px;
        margin: 24px 0;
        padding: 0;

        @include tablet() {
            min-height: 120px;
            margin: 8px 0;
            &:first-of-type {
                margin-top: 0;
            }
        }
    }
    .list__img-container {
        width: 150px;
        height: 150px;

        img {
            max-width: 100%;
        }

        @include tablet() {
            width: 120px;
            height: 120px;
        }
    }
    .list__text {
        width: calc(100% - 150px);
        padding: 32px 32px 32px 22px;
        box-sizing: border-box;

        @include tablet() {
            width: calc(100% - 120px);
            padding: 14px 12px 14px 10px;
        }
    }
}
