@import '../mixins';
@import '../variables';

.background-block {
    background: url("/background.jpg") center left no-repeat;
    background-size: 100%;
    height: 276px;
    position: relative;

    & + .container {
        position: relative;
        margin-top: -130px;

        @include tablet() {
            margin-top: 0;
        }
    }

    .title-container {
        position: relative;
        padding: 50px 0;

        * {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &:before {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(51, 51, 51, 0.75);
        top: 0;
        left: 0;
    }

    @include tablet() {
        display: none;
    }
}
