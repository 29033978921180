.contacts__cols {
  display: flex;
}

.contacts__list {
  width: 50%;
  list-style: none;
  padding: 0 5% 0 0;
  margin: 0;
  box-sizing: border-box;
}
.contacts__list .contacts__item {
  margin-bottom: 13px;
}
.contacts__list .contacts__item:last-of-type {
  margin-bottom: 0;
}

.contacts__item_phone .contacts__link,
.contacts__item_location .contacts__link,
.contacts__item_mail .contacts__link {
  padding-left: 29px;
  position: relative;
}
.contacts__item_phone .contacts__link:before,
.contacts__item_location .contacts__link:before,
.contacts__item_mail .contacts__link:before {
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 18px;
  left: 5px;
  top: 1px;
}

.contacts__item_phone .contacts__link {
  word-break: break-word;
}
.contacts__item_phone .contacts__link:before {
  background: url("/icons/phone.svg") center left no-repeat;
}

.contacts__item_location .contacts__link:before {
  background: url("/icons/location.svg") center center no-repeat;
}

.contacts__item_mail .contacts__link {
  word-break: break-word;
}
.contacts__item_mail .contacts__link:before {
  top: 2px;
  background: url("/icons/mail.svg") center center no-repeat;
}

.contacts__item_marker .contacts__title {
  color: #D67196;
}
.contacts__item_marker .contacts__link:before {
  top: 2px;
  background: url("/icons/phone-red.svg") center center no-repeat;
}

.contacts__title {
  display: block;
  margin-bottom: 4px;
}

.contacts__link {
  display: block;
  color: inherit;
  text-decoration: none;
}
.contacts__link span {
  margin-left: 10px;
}

.header {
  position: relative;
}
@media (max-width: 880px) {
  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #BDBDBD;
  }
}
@media (max-width: 400px) {
  .header {
    flex-direction: column;
  }
}

.header__top {
  padding: 7px 0;
}
@media (max-width: 880px) {
  .header__top {
    width: calc(100% - 55px);
  }
}
@media (max-width: 400px) {
  .header__top {
    width: 100%;
  }
}
.header__top .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 880px) {
  .header__top .container {
    padding-right: 0;
  }
}
@media (max-width: 400px) {
  .header__top .container {
    padding: 0 15px;
    flex-direction: column;
    text-align: center;
  }
}
.header__top .logo {
  width: 20%;
  max-width: 132px;
  max-height: 132px;
}
.header__top .logo .logo__img {
  width: 100%;
}
@media (max-width: 880px) {
  .header__top .logo {
    max-width: 100px;
    max-height: 100px;
    flex: none;
    width: 100px;
  }
}
.header__top .title {
  max-width: 382px;
}
@media (max-width: 880px) {
  .header__top .title {
    font-weight: 400;
    font-size: 0.8125rem;
  }
}
.header__top .title-container {
  width: 55%;
  word-wrap: break-word;
}
@media (max-width: 880px) {
  .header__top .title-container {
    width: 100%;
    padding-left: 5px;
  }
}
.header__top .desktop-search {
  max-width: 468px;
  margin-top: 14px;
}
@media (max-width: 880px) {
  .header__top .desktop-search {
    display: none;
  }
}
.header__top .contacts {
  width: 25%;
  max-width: 170px;
  list-style: none;
  padding: 0;
}
@media (max-width: 880px) {
  .header__top .contacts {
    display: none;
  }
}
.header__top .contacts__item {
  margin-bottom: 9px;
  word-wrap: break-word;
}
.header__top .contacts__item:last-of-type {
  margin-bottom: 0;
  margin-top: 15px;
}
@media (max-width: 880px) {
  .header__top .contacts__item:last-of-type {
    margin-top: 10px;
  }
}
@media (max-width: 400px) {
  .header__top .contacts__item {
    display: inline-block;
  }
}
.header__top .mobile-contacts {
  list-style: none;
  padding: 0;
  display: none;
}
@media (max-width: 880px) {
  .header__top .mobile-contacts {
    display: block;
    margin: 8px 0 0;
  }
}

.header__bottom {
  border-top: 1px solid #BDBDBD;
  border-bottom: 1px solid #BDBDBD;
}
@media (max-width: 880px) {
  .header__bottom {
    border: none;
    padding: 10px 0;
  }
}
@media (max-width: 400px) {
  .header__bottom {
    position: absolute;
    right: 0;
  }
}

@media (max-width: 880px) {
  .header__bottom .container {
    padding: 0 15px 0 0;
  }
}

.mobile-search {
  display: none;
}
@media (max-width: 880px) {
  .mobile-search {
    display: block;
    width: 100%;
    margin-bottom: 8px;
  }
}