.text-36 {
    font-size: 2.25rem;
}
.text-20 {
    font-size: 1.25rem;
}
.text-17 {
    font-size: 1.063rem;
}
.text-15 {
    font-size: 0.9375rem;
}
.text-14 {
    font-size: 0.875rem;
}
.text-13 {
    font-size: 0.8125rem;
}

.text-15-mobile {
    @include tablet() {
        font-size: 0.9375rem;
    }
}
.text-13-mobile {
    @include tablet() {
        font-size: 0.8125rem;
    }
}

.text_dark {
    color: $color-2;
}
.text_gray {
    color: $color-3;
}
.text_white {
    color: $color-6;
}
.text_green {
    color: $color-4;
}
.text_normal {
    font-weight: $regular;
}
.text_medium {
    font-weight: $medium;
}

.text-title {
    margin: 0;
}
.text-title-mobile {
    display: none;

    @include tablet() {
        display: block;
        font-size: 1rem;
        font-weight: $regular;
        color: $color-4;
        margin: 18px 0;
    }
}
.text-mobile {
    display: none;

    @include tablet() {
        display: block;
    }
}

.text-top-0 {
    margin-top: 0;
}
.text-bottom-0 {
    margin-bottom: 0;
}
.text-list {
    padding-left: 0;
    list-style: none;

    li {
        padding-left: 23px;
        position: relative;
        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 4px;
            height: 4px;
            background-color: $color-4;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
        }
    }
}
