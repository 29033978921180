.contacts__cols {
    display: flex;
}
.contacts__list {
    width: 50%;
    list-style: none;
    padding: 0 5% 0 0;
    margin: 0;
    box-sizing: border-box;

    .contacts__item {
        margin-bottom: 13px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
.contacts__item_phone,
.contacts__item_location,
.contacts__item_mail {
    .contacts__link {
        padding-left: 29px;
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 15px;
            height: 18px;
            left: 5px;
            top: 1px;
        }
    }
}
.contacts__item_phone {
    .contacts__link {
        word-break: break-word;
        &:before {
            background: url("/icons/phone.svg") center left no-repeat;
        }
    }
}
.contacts__item_location {
    .contacts__link {
        &:before {
            background: url("/icons/location.svg") center center no-repeat;
        }
    }
}
.contacts__item_mail {
    .contacts__link {
        word-break: break-word;
        &:before {
            top: 2px;
            background: url("/icons/mail.svg") center center no-repeat;
        }
    }
}
.contacts__item_marker {
    .contacts__title {
        color: $color-9;
    }
    .contacts__link {
        &:before {
            top: 2px;
            background: url("/icons/phone-red.svg") center center no-repeat;
        }
    }
}
.contacts__title {
    display: block;
    margin-bottom: 4px;
}
.contacts__link {
    display: block;
    color: inherit;
    text-decoration: none;

    span {
        margin-left: 10px;
    }
}
