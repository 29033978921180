.pagination {
    display: flex;
}
.pagination__button {
    min-width: 32px;
    max-width: 70px;
    height: 32px;
    vertical-align: middle;
    box-sizing: border-box;
    background-color: $color-6;
    border: 1px solid $color-7;
    border-right: none;
    padding: 0 3px;
    margin: 0;
    outline: none;
    cursor: pointer;
    transition: all 0.2s;
    overflow: hidden;
    color: $color-2;
    font-size: 0.8125rem;

    &:hover {
        background-color: $color-1;
        color: $color-4;
    }

    &:first-of-type {
        border-radius: 4px 0 0 4px;
    }
    &:last-of-type {
        border-radius: 0 4px 4px 0;
        border-right: 1px solid $color-7;
    }
}
.pagination__button_left {
    background: url("/icons/arrow-left.svg") 12px center no-repeat;
}
.pagination__button_right {
    background: url("/icons/arrow-right.svg") 8px center no-repeat;
}
.pagination__button_active {
    background-color: $color-1;
    color: $color-4;
    font-weight: $medium;
}
