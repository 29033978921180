.background-block {
  background: url("/background.jpg") center left no-repeat;
  background-size: 100%;
  height: 276px;
  position: relative;
}
.background-block + .container {
  position: relative;
  margin-top: -130px;
}
@media (max-width: 880px) {
  .background-block + .container {
    margin-top: 0;
  }
}
.background-block .title-container {
  position: relative;
  padding: 50px 0;
}
.background-block .title-container * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.background-block:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.75);
  top: 0;
  left: 0;
}
@media (max-width: 880px) {
  .background-block {
    display: none;
  }
}

.contacts__cols {
  display: flex;
}

.contacts__list {
  width: 50%;
  list-style: none;
  padding: 0 5% 0 0;
  margin: 0;
  box-sizing: border-box;
}
.contacts__list .contacts__item {
  margin-bottom: 13px;
}
.contacts__list .contacts__item:last-of-type {
  margin-bottom: 0;
}

.contacts__item_phone .contacts__link,
.contacts__item_location .contacts__link,
.contacts__item_mail .contacts__link {
  padding-left: 29px;
  position: relative;
}
.contacts__item_phone .contacts__link:before,
.contacts__item_location .contacts__link:before,
.contacts__item_mail .contacts__link:before {
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 18px;
  left: 5px;
  top: 1px;
}

.contacts__item_phone .contacts__link {
  word-break: break-word;
}
.contacts__item_phone .contacts__link:before {
  background: url("/icons/phone.svg") center left no-repeat;
}

.contacts__item_location .contacts__link:before {
  background: url("/icons/location.svg") center center no-repeat;
}

.contacts__item_mail .contacts__link {
  word-break: break-word;
}
.contacts__item_mail .contacts__link:before {
  top: 2px;
  background: url("/icons/mail.svg") center center no-repeat;
}

.contacts__item_marker .contacts__title {
  color: #D67196;
}
.contacts__item_marker .contacts__link:before {
  top: 2px;
  background: url("/icons/phone-red.svg") center center no-repeat;
}

.contacts__title {
  display: block;
  margin-bottom: 4px;
}

.contacts__link {
  display: block;
  color: inherit;
  text-decoration: none;
}
.contacts__link span {
  margin-left: 10px;
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 70px 0 0;
}

.button {
  margin-top: 20px;
}
.button button {
  cursor: pointer;
  width: 150px;
  height: 40px;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #FFFFFF;
  outline: none;
  transition: 0.2s border-color;
}

.dropzone-wrapper {
  padding: 20px;
  margin: 20px;
  border: 2px dashed black;
  text-align: center;
  cursor: pointer;
}

.dropzone-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.introduction {
  display: flex;
  width: 990px;
  flex-direction: column;
}
.introduction > ul {
  margin: 15px 0;
}
.introduction > ul > li {
  line-height: 2em;
}

.file-view {
  margin: 20px 0;
}

.errors {
  color: red;
  margin-top: 25px;
}
.errors .error {
  padding-bottom: 15px;
}
.errors .error span {
  padding-right: 15px;
}

.success {
  color: green;
  margin-top: 25px;
}