@font-face {
  font-family: "IBM Plex Sans";
  src: url("/fonts/IBMPlexSans-Medium.eot");
  src: url("/fonts/IBMPlexSans-Medium.eot?#iefix") format("embedded-opentype"), url("/fonts/IBMPlexSans-Medium.woff2") format("woff2"), url("/fonts/IBMPlexSans-Medium.woff") format("woff"), url("/fonts/IBMPlexSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "IBM Plex Sans";
  src: url("/fonts/IBMPlexSans.eot");
  src: url("/fonts/IBMPlexSans.eot?#iefix") format("embedded-opentype"), url("/fonts/IBMPlexSans.woff2") format("woff2"), url("/fonts/IBMPlexSans.woff") format("woff"), url("/fonts/IBMPlexSans.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html #root,
body #root {
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 550px;
  min-width: 290px;
  position: relative;
}
@media (max-width: 880px) {
  .wrapper {
    display: table;
  }
}

.page-background {
  background-color: #FAFEF6;
}

.container {
  width: 990px;
  margin: auto;
  padding: 0 15px;
  box-sizing: border-box;
}
@media (max-width: 1000px) {
  .container {
    width: 100%;
  }
}

.container-style {
  display: block;
  box-sizing: border-box;
  padding: 32px;
  margin-bottom: 15px;
  border-radius: 2px;
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0;
}
@media (max-width: 880px) {
  .container-style {
    padding: 14px;
  }
}

.text-36 {
  font-size: 2.25rem;
}

.text-20 {
  font-size: 1.25rem;
}

.text-17 {
  font-size: 1.063rem;
}

.text-15 {
  font-size: 0.9375rem;
}

.text-14 {
  font-size: 0.875rem;
}

.text-13 {
  font-size: 0.8125rem;
}

@media (max-width: 880px) {
  .text-15-mobile {
    font-size: 0.9375rem;
  }
}

@media (max-width: 880px) {
  .text-13-mobile {
    font-size: 0.8125rem;
  }
}

.text_dark {
  color: #333333;
}

.text_gray {
  color: #828282;
}

.text_white {
  color: #FFFFFF;
}

.text_green {
  color: #7CB342;
}

.text_normal {
  font-weight: 400;
}

.text_medium {
  font-weight: 500;
}

.text-title {
  margin: 0;
}

.text-title-mobile {
  display: none;
}
@media (max-width: 880px) {
  .text-title-mobile {
    display: block;
    font-size: 1rem;
    font-weight: 400;
    color: #7CB342;
    margin: 18px 0;
  }
}

.text-mobile {
  display: none;
}
@media (max-width: 880px) {
  .text-mobile {
    display: block;
  }
}

.text-top-0 {
  margin-top: 0;
}

.text-bottom-0 {
  margin-bottom: 0;
}

.text-list {
  padding-left: 0;
  list-style: none;
}
.text-list li {
  padding-left: 23px;
  position: relative;
}
.text-list li:before {
  content: "";
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: #7CB342;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 50%;
}
.navigation-container {
  position: relative;
}

.navigation {
  display: flex;
  list-style: none;
  padding: 0;
  text-transform: uppercase;
  flex-wrap: wrap;
}
@media (max-width: 880px) {
  .navigation {
    visibility: hidden;
    margin-left: -9999px;
    opacity: 0;
    width: 157px;
    max-height: 80vh;
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    background-color: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #E0E0E0;
    margin: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-transform: none;
    font-weight: 400;
    font-size: 0.9375rem;
    overflow-y: auto;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.3s;
    transition-delay: 0s, 0s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 90% 0;
            transform-origin: 90% 0;
    opacity: 0;
  }
}

.active-dropdown {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
@media (max-width: 880px) {
  .active-dropdown + .navigation {
    visibility: visible;
    margin-left: 0;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.navigation__item {
  margin-right: 36px;
}
.navigation__item:last-of-type {
  margin-right: 0;
}
@media (max-width: 880px) {
  .navigation__item {
    margin: 0;
    width: 100%;
    border-bottom: 1px solid #E0E0E0;
    box-sizing: border-box;
    padding: 18px 16px;
  }
  .navigation__item:last-of-type {
    border-bottom: none;
  }
}

.navigation__link {
  color: inherit;
  text-decoration: none;
  transition: opacity 0.2s;
}
.navigation__link:hover {
  opacity: 0.8;
}

.navigation__link.active {
  color: #7CB342;
}
@media (max-width: 880px) {
  .navigation__link.active {
    color: #BDBDBD;
  }
}

.navigation__link.navigation__link_icon {
  padding-left: 40px;
  position: relative;
}
.navigation__link.navigation__link_icon:before {
  content: "";
  display: block;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: url("/icons/percent.svg");
}
@media (max-width: 880px) {
  .navigation__link.navigation__link_icon {
    display: block;
    position: relative;
    padding-left: 0;
  }
  .navigation__link.navigation__link_icon:before {
    right: 0;
    left: inherit;
  }
}

.navigation-button {
  display: none;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border: 2px solid #7CB342;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: 0.2s background-color;
  background-color: transparent;
}
.navigation-button:hover {
  background-color: #7CB342;
}
.navigation-button:hover .navigation-button__element {
  background-color: #FFFFFF;
}
.navigation-button .navigation-button__element {
  width: 16px;
  height: 2px;
  border-radius: 20px;
  background-color: #7CB342;
  box-sizing: border-box;
  margin-bottom: 4px;
}
.navigation-button .navigation-button__element:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 880px) {
  .navigation-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.search {
  width: 100%;
  display: flex;
  align-items: center;
}

.search__input {
  width: calc(100% - 27px);
  height: 40px;
  border: 1px solid #828282;
  border-right: none;
  box-sizing: border-box;
  border-radius: 4px 0 0 4px;
  background-color: #FFFFFF;
  outline: none;
  padding: 0 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: 0.2s border-color;
}
.search__input:focus, .search__input:focus + .search__button {
  border-color: #7CB342;
}
.search__input::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
  font-size: 0.9375rem;
  color: #BDBDBD;
}
.search__input::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
  font-size: 0.9375rem;
  color: #BDBDBD;
}
.search__input:-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
  font-size: 0.9375rem;
  color: #BDBDBD;
}
.search__input:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
  font-size: 0.9375rem;
  color: #BDBDBD;
}
.search__input:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 0.9375rem;
  color: #BDBDBD;
}
.search__input:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 0.9375rem;
  color: #BDBDBD;
}
.search__input:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 0.9375rem;
  color: #BDBDBD;
}
.search__input:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 0.9375rem;
  color: #BDBDBD;
}

.search__button {
  width: 27px;
  height: 40px;
  border: 1px solid #828282;
  border-left: none;
  box-sizing: border-box;
  border-radius: 0 4px 4px 0;
  background: url("/icons/search.svg") center left no-repeat, #FFFFFF;
  outline: none;
  cursor: pointer;
}
.contacts__cols {
  display: flex;
}

.contacts__list {
  width: 50%;
  list-style: none;
  padding: 0 5% 0 0;
  margin: 0;
  box-sizing: border-box;
}
.contacts__list .contacts__item {
  margin-bottom: 13px;
}
.contacts__list .contacts__item:last-of-type {
  margin-bottom: 0;
}

.contacts__item_phone .contacts__link,
.contacts__item_location .contacts__link,
.contacts__item_mail .contacts__link {
  padding-left: 29px;
  position: relative;
}
.contacts__item_phone .contacts__link:before,
.contacts__item_location .contacts__link:before,
.contacts__item_mail .contacts__link:before {
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 18px;
  left: 5px;
  top: 1px;
}

.contacts__item_phone .contacts__link {
  word-break: break-word;
}
.contacts__item_phone .contacts__link:before {
  background: url("/icons/phone.svg") center left no-repeat;
}

.contacts__item_location .contacts__link:before {
  background: url("/icons/location.svg") center center no-repeat;
}

.contacts__item_mail .contacts__link {
  word-break: break-word;
}
.contacts__item_mail .contacts__link:before {
  top: 2px;
  background: url("/icons/mail.svg") center center no-repeat;
}

.contacts__item_marker .contacts__title {
  color: #D67196;
}
.contacts__item_marker .contacts__link:before {
  top: 2px;
  background: url("/icons/phone-red.svg") center center no-repeat;
}

.contacts__title {
  display: block;
  margin-bottom: 4px;
}

.contacts__link {
  display: block;
  color: inherit;
  text-decoration: none;
}
.contacts__link span {
  margin-left: 10px;
}

.header {
  position: relative;
}
@media (max-width: 880px) {
  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #BDBDBD;
  }
}
@media (max-width: 400px) {
  .header {
    flex-direction: column;
  }
}

.header__top {
  padding: 7px 0;
}
@media (max-width: 880px) {
  .header__top {
    width: calc(100% - 55px);
  }
}
@media (max-width: 400px) {
  .header__top {
    width: 100%;
  }
}
.header__top .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 880px) {
  .header__top .container {
    padding-right: 0;
  }
}
@media (max-width: 400px) {
  .header__top .container {
    padding: 0 15px;
    flex-direction: column;
    text-align: center;
  }
}
.header__top .logo {
  width: 20%;
  max-width: 132px;
  max-height: 132px;
}
.header__top .logo .logo__img {
  width: 100%;
}
@media (max-width: 880px) {
  .header__top .logo {
    max-width: 100px;
    max-height: 100px;
    flex: none;
    width: 100px;
  }
}
.header__top .title {
  max-width: 382px;
}
@media (max-width: 880px) {
  .header__top .title {
    font-weight: 400;
    font-size: 0.8125rem;
  }
}
.header__top .title-container {
  width: 55%;
  word-wrap: break-word;
}
@media (max-width: 880px) {
  .header__top .title-container {
    width: 100%;
    padding-left: 5px;
  }
}
.header__top .desktop-search {
  max-width: 468px;
  margin-top: 14px;
}
@media (max-width: 880px) {
  .header__top .desktop-search {
    display: none;
  }
}
.header__top .contacts {
  width: 25%;
  max-width: 170px;
  list-style: none;
  padding: 0;
}
@media (max-width: 880px) {
  .header__top .contacts {
    display: none;
  }
}
.header__top .contacts__item {
  margin-bottom: 9px;
  word-wrap: break-word;
}
.header__top .contacts__item:last-of-type {
  margin-bottom: 0;
  margin-top: 15px;
}
@media (max-width: 880px) {
  .header__top .contacts__item:last-of-type {
    margin-top: 10px;
  }
}
@media (max-width: 400px) {
  .header__top .contacts__item {
    display: inline-block;
  }
}
.header__top .mobile-contacts {
  list-style: none;
  padding: 0;
  display: none;
}
@media (max-width: 880px) {
  .header__top .mobile-contacts {
    display: block;
    margin: 8px 0 0;
  }
}

.header__bottom {
  border-top: 1px solid #BDBDBD;
  border-bottom: 1px solid #BDBDBD;
}
@media (max-width: 880px) {
  .header__bottom {
    border: none;
    padding: 10px 0;
  }
}
@media (max-width: 400px) {
  .header__bottom {
    position: absolute;
    right: 0;
  }
}

@media (max-width: 880px) {
  .header__bottom .container {
    padding: 0 15px 0 0;
  }
}

.mobile-search {
  display: none;
}
@media (max-width: 880px) {
  .mobile-search {
    display: block;
    width: 100%;
    margin-bottom: 8px;
  }
}
.background-block {
  background: url("/background.jpg") center left no-repeat;
  background-size: 100%;
  height: 276px;
  position: relative;
}
.background-block + .container {
  position: relative;
  margin-top: -130px;
}
@media (max-width: 880px) {
  .background-block + .container {
    margin-top: 0;
  }
}
.background-block .title-container {
  position: relative;
  padding: 50px 0;
}
.background-block .title-container * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.background-block:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.75);
  top: 0;
  left: 0;
}
@media (max-width: 880px) {
  .background-block {
    display: none;
  }
}
@charset "UTF-8";
.catalog-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-radius: 0;
  border-top: none;
  border-bottom: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
}
@media (max-width: 880px) {
  .catalog-list {
    height: auto;
    background: transparent;
    border: none;
  }
}

.catalog-list__mobile {
  display: none;
}
@media (max-width: 880px) {
  .catalog-list__mobile {
    display: inline-block;
  }
}

.catalog-list__header {
  display: flex;
  align-items: center;
  padding: 14px;
  border-bottom: 1px solid #E0E0E0;
}
@media (max-width: 880px) {
  .catalog-list__header {
    display: none;
  }
}

.catalog-list__footer {
  display: flex;
  flex: 0 0 48px;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  box-sizing: border-box;
  border-top: 1px solid #E0E0E0;
}
@media (max-width: 880px) {
  .catalog-list__footer {
    padding: 14px;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    background-color: #FFFFFF;
    margin-bottom: 8px;
  }
}

.catalog-list__main {
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.catalog-list__main::-webkit-scrollbar {
  width: 0px;
}

.catalog-list__products {
  list-style: none;
  padding: 0 14px;
  position: relative;
}
@media (max-width: 880px) {
  .catalog-list__products {
    padding: 0;
  }
}

.catalog-list__product {
  display: flex;
  padding: 18px 0;
  border-bottom: 1px solid #E0E0E0;
}
.catalog-list__product:last-of-type {
  border-bottom: 0;
}
@media (max-width: 880px) {
  .catalog-list__product {
    flex-wrap: wrap;
    padding: 14px;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    background-color: #FFFFFF;
    margin-bottom: 8px;
  }
  .catalog-list__product:last-of-type {
    border: 1px solid #E0E0E0;
  }
}

.catalog-list__price {
  display: block;
  width: 100%;
  padding-left: 26px;
  position: relative;
  box-sizing: border-box;
}
.catalog-list__price:before {
  content: "";
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  top: 0;
  background: url("/icons/card.svg");
}
@media (max-width: 880px) {
  .catalog-list__price {
    display: inline;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

@media (max-width: 880px) {
  .catalog-list__price_mobile {
    margin-right: 15px;
  }
}

.catalog-list__promoutions {
  padding-right: 32px;
  position: relative;
}
.catalog-list__promoutions:after {
  content: "";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  right: 0;
  top: -6px;
  background: url("/icons/percent.svg");
}
@media (max-width: 880px) {
  .catalog-list__promoutions:after {
    right: -10px;
    top: auto;
    bottom: -7px;
  }
}
@media (max-width: 880px) {
  .catalog-list__promoutions {
    padding-right: 22px;
  }
}

.catalog-list__col1,
.catalog-list__col2,
.catalog-list__col3 {
  padding-right: 15px;
}
@media (max-width: 880px) {
  .catalog-list__col1,
  .catalog-list__col2,
  .catalog-list__col3 {
    padding-right: 0;
  }
}

.catalog-list__col1,
.catalog-list__col2,
.catalog-list__col3,
.catalog-list__col4 {
  display: block;
  box-sizing: border-box;
}

.catalog-list__col1 {
  width: 51.5%;
}
@media (max-width: 880px) {
  .catalog-list__col1 {
    width: 100%;
    order: 1;
    margin-bottom: 12px;
  }
}

.catalog-list__col2 {
  width: 17.5%;
}
@media (max-width: 880px) {
  .catalog-list__col2 {
    width: auto;
    max-width: 75%;
    order: 3;
    margin-bottom: 12px;
  }
}

.catalog-list__col3 {
  width: 9.6%;
}
@media (max-width: 880px) {
  .catalog-list__col3 {
    width: auto;
    max-width: 25%;
    order: 2;
    padding-right: 14px;
  }
}

.catalog-list__col4 {
  width: 21.4%;
}
@media (max-width: 880px) {
  .catalog-list__col4 {
    width: 100%;
    order: 4;
  }
}

.pagination {
  display: flex;
}

.pagination__button {
  min-width: 32px;
  max-width: 70px;
  height: 32px;
  vertical-align: middle;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-right: none;
  padding: 0 3px;
  margin: 0;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;
  overflow: hidden;
  color: #333333;
  font-size: 0.8125rem;
}
.pagination__button:hover {
  background-color: #FAFEF6;
  color: #7CB342;
}
.pagination__button:first-of-type {
  border-radius: 4px 0 0 4px;
}
.pagination__button:last-of-type {
  border-radius: 0 4px 4px 0;
  border-right: 1px solid #BDBDBD;
}

.pagination__button_left {
  background: url("/icons/arrow-left.svg") 12px center no-repeat;
}

.pagination__button_right {
  background: url("/icons/arrow-right.svg") 8px center no-repeat;
}

.pagination__button_active {
  background-color: #FAFEF6;
  color: #7CB342;
  font-weight: 500;
}

.сatalog {
  flex: 1 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.catalog__container {
  height: 100%;
}
.background-block {
  background: url("/background.jpg") center left no-repeat;
  background-size: 100%;
  height: 276px;
  position: relative;
}
.background-block + .container {
  position: relative;
  margin-top: -130px;
}
@media (max-width: 880px) {
  .background-block + .container {
    margin-top: 0;
  }
}
.background-block .title-container {
  position: relative;
  padding: 50px 0;
}
.background-block .title-container * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.background-block:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.75);
  top: 0;
  left: 0;
}
@media (max-width: 880px) {
  .background-block {
    display: none;
  }
}

.contacts__cols {
  display: flex;
}

.contacts__list {
  width: 50%;
  list-style: none;
  padding: 0 5% 0 0;
  margin: 0;
  box-sizing: border-box;
}
.contacts__list .contacts__item {
  margin-bottom: 13px;
}
.contacts__list .contacts__item:last-of-type {
  margin-bottom: 0;
}

.contacts__item_phone .contacts__link,
.contacts__item_location .contacts__link,
.contacts__item_mail .contacts__link {
  padding-left: 29px;
  position: relative;
}
.contacts__item_phone .contacts__link:before,
.contacts__item_location .contacts__link:before,
.contacts__item_mail .contacts__link:before {
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 18px;
  left: 5px;
  top: 1px;
}

.contacts__item_phone .contacts__link {
  word-break: break-word;
}
.contacts__item_phone .contacts__link:before {
  background: url("/icons/phone.svg") center left no-repeat;
}

.contacts__item_location .contacts__link:before {
  background: url("/icons/location.svg") center center no-repeat;
}

.contacts__item_mail .contacts__link {
  word-break: break-word;
}
.contacts__item_mail .contacts__link:before {
  top: 2px;
  background: url("/icons/mail.svg") center center no-repeat;
}

.contacts__item_marker .contacts__title {
  color: #D67196;
}
.contacts__item_marker .contacts__link:before {
  top: 2px;
  background: url("/icons/phone-red.svg") center center no-repeat;
}

.contacts__title {
  display: block;
  margin-bottom: 4px;
}

.contacts__link {
  display: block;
  color: inherit;
  text-decoration: none;
}
.contacts__link span {
  margin-left: 10px;
}

@media (max-width: 880px) {
  .contacts-page .contacts__cols {
    flex-direction: column;
    background: transparent;
    border: none;
    padding: 0;
  }
}
@media (max-width: 880px) {
  .contacts-page .contacts__list {
    width: 100%;
    padding: 14px;
    background-color: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #E0E0E0;
    margin-bottom: 8px;
  }
  .contacts-page .contacts__list:last-of-type {
    margin-bottom: 0;
  }
}
.list {
  margin: 0;
  padding: 0;
}

.list-with-img .list__item {
  display: flex;
  min-height: 150px;
  margin: 24px 0;
  padding: 0;
}
@media (max-width: 880px) {
  .list-with-img .list__item {
    min-height: 120px;
    margin: 8px 0;
  }
  .list-with-img .list__item:first-of-type {
    margin-top: 0;
  }
}
.list-with-img .list__img-container {
  width: 150px;
  height: 150px;
}
.list-with-img .list__img-container img {
  max-width: 100%;
}
@media (max-width: 880px) {
  .list-with-img .list__img-container {
    width: 120px;
    height: 120px;
  }
}
.list-with-img .list__text {
  width: calc(100% - 150px);
  padding: 32px 32px 32px 22px;
  box-sizing: border-box;
}
@media (max-width: 880px) {
  .list-with-img .list__text {
    width: calc(100% - 120px);
    padding: 14px 12px 14px 10px;
  }
}
.catalog-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-radius: 0;
  border-top: none;
  border-bottom: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
}
@media (max-width: 880px) {
  .catalog-list {
    height: auto;
    background: transparent;
    border: none;
  }
}

.catalog-list__mobile {
  display: none;
}
@media (max-width: 880px) {
  .catalog-list__mobile {
    display: inline-block;
  }
}

.catalog-list__header {
  display: flex;
  align-items: center;
  padding: 14px;
  border-bottom: 1px solid #E0E0E0;
}
@media (max-width: 880px) {
  .catalog-list__header {
    display: none;
  }
}

.catalog-list__footer {
  display: flex;
  flex: 0 0 48px;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  box-sizing: border-box;
  border-top: 1px solid #E0E0E0;
}
@media (max-width: 880px) {
  .catalog-list__footer {
    padding: 14px;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    background-color: #FFFFFF;
    margin-bottom: 8px;
  }
}

.catalog-list__main {
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.catalog-list__main::-webkit-scrollbar {
  width: 0px;
}

.catalog-list__products {
  list-style: none;
  padding: 0 14px;
  position: relative;
}
@media (max-width: 880px) {
  .catalog-list__products {
    padding: 0;
  }
}

.catalog-list__product {
  display: flex;
  padding: 18px 0;
  border-bottom: 1px solid #E0E0E0;
}
.catalog-list__product:last-of-type {
  border-bottom: 0;
}
@media (max-width: 880px) {
  .catalog-list__product {
    flex-wrap: wrap;
    padding: 14px;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    background-color: #FFFFFF;
    margin-bottom: 8px;
  }
  .catalog-list__product:last-of-type {
    border: 1px solid #E0E0E0;
  }
}

.catalog-list__price {
  display: block;
  width: 100%;
  padding-left: 26px;
  position: relative;
  box-sizing: border-box;
}
.catalog-list__price:before {
  content: "";
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  top: 0;
  background: url("/icons/card.svg");
}
@media (max-width: 880px) {
  .catalog-list__price {
    display: inline;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

@media (max-width: 880px) {
  .catalog-list__price_mobile {
    margin-right: 15px;
  }
}

.catalog-list__promoutions {
  padding-right: 32px;
  position: relative;
}
.catalog-list__promoutions:after {
  content: "";
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  right: 0;
  top: -6px;
  background: url("/icons/percent.svg");
}
@media (max-width: 880px) {
  .catalog-list__promoutions:after {
    right: -10px;
    top: auto;
    bottom: -7px;
  }
}
@media (max-width: 880px) {
  .catalog-list__promoutions {
    padding-right: 22px;
  }
}

.catalog-list__col1,
.catalog-list__col2,
.catalog-list__col3 {
  padding-right: 15px;
}
@media (max-width: 880px) {
  .catalog-list__col1,
  .catalog-list__col2,
  .catalog-list__col3 {
    padding-right: 0;
  }
}

.catalog-list__col1,
.catalog-list__col2,
.catalog-list__col3,
.catalog-list__col4 {
  display: block;
  box-sizing: border-box;
}

.catalog-list__col1 {
  width: 51.5%;
}
@media (max-width: 880px) {
  .catalog-list__col1 {
    width: 100%;
    order: 1;
    margin-bottom: 12px;
  }
}

.catalog-list__col2 {
  width: 17.5%;
}
@media (max-width: 880px) {
  .catalog-list__col2 {
    width: auto;
    max-width: 75%;
    order: 3;
    margin-bottom: 12px;
  }
}

.catalog-list__col3 {
  width: 9.6%;
}
@media (max-width: 880px) {
  .catalog-list__col3 {
    width: auto;
    max-width: 25%;
    order: 2;
    padding-right: 14px;
  }
}

.catalog-list__col4 {
  width: 21.4%;
}
@media (max-width: 880px) {
  .catalog-list__col4 {
    width: 100%;
    order: 4;
  }
}

.pagination {
  display: flex;
}

.pagination__button {
  min-width: 32px;
  max-width: 70px;
  height: 32px;
  vertical-align: middle;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-right: none;
  padding: 0 3px;
  margin: 0;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;
  overflow: hidden;
  color: #333333;
  font-size: 0.8125rem;
}
.pagination__button:hover {
  background-color: #FAFEF6;
  color: #7CB342;
}
.pagination__button:first-of-type {
  border-radius: 4px 0 0 4px;
}
.pagination__button:last-of-type {
  border-radius: 0 4px 4px 0;
  border-right: 1px solid #BDBDBD;
}

.pagination__button_left {
  background: url("/icons/arrow-left.svg") 12px center no-repeat;
}

.pagination__button_right {
  background: url("/icons/arrow-right.svg") 8px center no-repeat;
}

.pagination__button_active {
  background-color: #FAFEF6;
  color: #7CB342;
  font-weight: 500;
}

.promotions {
  flex: 1 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.promotions .text-title-mobile {
  color: #D67196;
}

.promotions__container {
  height: 100%;
}
.img-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin: 32px -5px -25px 0;
  list-style: none;
}
@media (max-width: 880px) {
  .img-items {
    margin: 14px -5px -14px 0;
  }
}

.img-card {
  width: 31.545%;
  max-width: 282px;
  height: 282px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-bottom: 25px;
  margin-right: 5px;
}
@media (max-width: 880px) {
  .img-card {
    width: 120px;
    height: 120px;
    margin-bottom: 14px;
  }
}
@media (max-width: 400px) {
  .img-card {
    width: 110px;
    height: 110px;
  }
}
.img-card img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-card div {
  display: none;
}
.img-card .img-card__small {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.img-card .img-card__small:after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 4px;
  transition: background-color 0.2s;
}
.img-card .img-card__small:hover:after {
  background: url("/icons/look.svg") center center no-repeat, rgba(51, 51, 51, 0.75);
}
@media (max-width: 880px) {
  .img-card .img-card__small:after {
    background-size: 20%;
  }
}
.img-card .img-card__large {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
.img-card .img-card__large img {
  width: auto;
  height: auto;
  max-width: 95vw;
  max-height: 95vh;
}
.img-card .img-card__preloader {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #fff;
}
.img-card .img-hidden {
  display: none;
}
.img-card .svg {
  width: 50px;
  height: 50px;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  -webkit-animation: rotate 2s cubic-bezier(0.51, 0.92, 0.24, 1) forwards infinite;
          animation: rotate 2s cubic-bezier(0.51, 0.92, 0.24, 1) forwards infinite;
}
.img-card .path {
  stroke-dasharray: 590;
  stroke-dashoffset: 0;
  stroke: #7CB342;
  fill: none;
  -webkit-animation: dash 2s cubic-bezier(0.51, 0.92, 0.24, 1) forwards infinite;
          animation: dash 2s cubic-bezier(0.51, 0.92, 0.24, 1) forwards infinite;
}
@-webkit-keyframes dash {
  from {
    stroke-dashoffset: 1200;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  from {
    stroke-dashoffset: 1200;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
  to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}
@keyframes rotate {
  from {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
  to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

.supply .container-style {
  margin: 24px 0;
}
.background-block {
  background: url("/background.jpg") center left no-repeat;
  background-size: 100%;
  height: 276px;
  position: relative;
}
.background-block + .container {
  position: relative;
  margin-top: -130px;
}
@media (max-width: 880px) {
  .background-block + .container {
    margin-top: 0;
  }
}
.background-block .title-container {
  position: relative;
  padding: 50px 0;
}
.background-block .title-container * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.background-block:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.75);
  top: 0;
  left: 0;
}
@media (max-width: 880px) {
  .background-block {
    display: none;
  }
}

.contacts__cols {
  display: flex;
}

.contacts__list {
  width: 50%;
  list-style: none;
  padding: 0 5% 0 0;
  margin: 0;
  box-sizing: border-box;
}
.contacts__list .contacts__item {
  margin-bottom: 13px;
}
.contacts__list .contacts__item:last-of-type {
  margin-bottom: 0;
}

.contacts__item_phone .contacts__link,
.contacts__item_location .contacts__link,
.contacts__item_mail .contacts__link {
  padding-left: 29px;
  position: relative;
}
.contacts__item_phone .contacts__link:before,
.contacts__item_location .contacts__link:before,
.contacts__item_mail .contacts__link:before {
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 18px;
  left: 5px;
  top: 1px;
}

.contacts__item_phone .contacts__link {
  word-break: break-word;
}
.contacts__item_phone .contacts__link:before {
  background: url("/icons/phone.svg") center left no-repeat;
}

.contacts__item_location .contacts__link:before {
  background: url("/icons/location.svg") center center no-repeat;
}

.contacts__item_mail .contacts__link {
  word-break: break-word;
}
.contacts__item_mail .contacts__link:before {
  top: 2px;
  background: url("/icons/mail.svg") center center no-repeat;
}

.contacts__item_marker .contacts__title {
  color: #D67196;
}
.contacts__item_marker .contacts__link:before {
  top: 2px;
  background: url("/icons/phone-red.svg") center center no-repeat;
}

.contacts__title {
  display: block;
  margin-bottom: 4px;
}

.contacts__link {
  display: block;
  color: inherit;
  text-decoration: none;
}
.contacts__link span {
  margin-left: 10px;
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 70px 0 0;
}

.button {
  margin-top: 20px;
}
.button button {
  cursor: pointer;
  width: 150px;
  height: 40px;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #FFFFFF;
  outline: none;
  transition: 0.2s border-color;
}

.dropzone-wrapper {
  padding: 20px;
  margin: 20px;
  border: 2px dashed black;
  text-align: center;
  cursor: pointer;
}

.dropzone-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.introduction {
  display: flex;
  width: 990px;
  flex-direction: column;
}
.introduction > ul {
  margin: 15px 0;
}
.introduction > ul > li {
  line-height: 2em;
}

.file-view {
  margin: 20px 0;
}

.errors {
  color: red;
  margin-top: 25px;
}
.errors .error {
  padding-bottom: 15px;
}
.errors .error span {
  padding-right: 15px;
}

.success {
  color: green;
  margin-top: 25px;
}
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  z-index: 1000;
}

.preloader__icon {
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.preloader__circle {
  width: 35px;
  height: 35px;
  border: 2px solid #7CB342;
  border-radius: 50%;
  transition: 1s;
  -webkit-animation: bounce 1s ease-in-out infinite both;
          animation: bounce 1s ease-in-out infinite both;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  90% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  90% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
